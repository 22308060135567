import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
export function aboutUs(scene, trigger, rotation) {
    gsap.registerPlugin(ScrollTrigger)
    const element = scene.children[0].children[2]
    gsap.fromTo(element.rotation, {
        y: 0,
    }, {
        y: 20,
        // duration: 10.5,
        // ease: "power4.inOut",
        scrollTrigger: {
            trigger: "#home",
            start: "top top",
            scrub: 3,
            end: () => "+=" + document.documentElement.scrollHeight - window.innerHeight
        }
    });
}