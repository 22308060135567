import React, { useEffect, useRef } from 'react'
import { PMREMGenerator, Clock, WebGLRenderer, ACESFilmicToneMapping, sRGBEncoding, Scene, PerspectiveCamera, AnimationMixer, LoadingManager, LoopOnce, MeshPhysicalMaterial, Vector2, VideoTexture, LinearFilter } from "three"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

// postprocessing
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';

import Stats from 'three/addons/libs/stats.module.js';
import { RoomEnvironment } from 'three/examples/jsm/environments/RoomEnvironment.js';
import * as dat from "lil-gui"
import "./index.scss"
import { fragment, vertex } from './materials/materialIntergration';
import { useDispatch } from 'react-redux';
import { setPercentage } from '../redux/mainPageSlice';
import { aboutUs } from './animations/aboutus';

export default function Webgl() {
    let container = useRef()
    const dispatch = useDispatch()
    useEffect(() => {
        let scene, camera, renderer, mixer, composer, loadingManager, customShader, customUniforms, videoTexture, animation, video, mesh;
        const stats = new Stats();
        const clock = new Clock();

        const gui = new dat.GUI()
        gui.destroy()
        gui.close()

        const handleResponsiveExposure = () => {
            if (window.innerWidth > 600) {
                renderer.toneMappingExposure = 0.3204757315051775;
            }
            if (window.innerWidth <= 600) {
                renderer.toneMappingExposure = 2.5;
            }
        }

        const createRender = () => {
            renderer = new WebGLRenderer({ antialias: false });
            renderer.setClearColor("#ffffff");
            renderer.setPixelRatio(window.devicePixelRatio * 2);
            renderer.setSize(container?.current?.clientWidth, container?.current?.clientHeight);
            renderer.toneMapping = ACESFilmicToneMapping;
            renderer.outputEncoding = sRGBEncoding;
            handleResponsiveExposure()
            renderer.physicallyCorrectLights = true
            const pmremGenerator = new PMREMGenerator(renderer);
            pmremGenerator.compileEquirectangularShader();
            scene.environment = pmremGenerator.fromScene(new RoomEnvironment()).texture;
            renderer.domElement.setAttribute('class', 'webgl-rendering');
            container.current.appendChild(renderer.domElement);
        }

        // post processing
        const createComposer = () => {
            composer = new EffectComposer(renderer);
            composer.addPass(new RenderPass(scene, camera));
        }

        const debugExposure = () => {
            const toneMappingFolder = gui.addFolder('ToneMappingFolder');
            toneMappingFolder.add(renderer, 'toneMappingExposure', 0.1, 2).onChange(function (value) {

                renderer.toneMappingExposure = Math.pow(value, 4.0);

            });
            toneMappingFolder.add(camera.position, 'x', -100, 100).onChange(function (value) {

                camera.position.x = value;

            });
        }

        const manageRenderSize = () => {
            let appContainer = container.current
            return { width: appContainer.clientWidth, height: appContainer.clientHeight }
        }

        const handleLoading = () => {
            loadingManager = new LoadingManager(() => {
                createComposer()
                debugExposure()
                setTimeout(() => {
                    animation.play()
                    video.play();
                    animate();
                }, 2000)
                handleAnimation()
            }, (url, numberLoaded, allObj) => {
                dispatch(setPercentage(parseInt((numberLoaded / allObj) * 100)))
                // console.log("percentage", parseInt((numberLoaded / allObj) * 100))
            })
        }

        const createScene = () => {
            scene = new Scene()
        }

        const createCamera = () => {
            let fieldOfView = 22.89519413064574;
            const { width, height } = manageRenderSize()
            let aspectRatio = width / height;
            let nearPlane = 0.1;
            let farPlane = 3500000;
            camera = new PerspectiveCamera(fieldOfView, aspectRatio, nearPlane, farPlane);
        }

        const render = () => {
            const delta = clock.getDelta();

            mixer.update(delta);
            videoTexture.needsUpdate = true;
            customUniforms.u_time.value = performance.now() / 1000;
            customUniforms.custom_resolution.value.set(window.innerWidth, window.innerHeight);
            stats.update();

            composer.render();
        }

        const animate = () => {
            requestAnimationFrame(animate);
            render()
        }

        const circleShader = () => {
            customShader = new MeshPhysicalMaterial();

            video = document.createElement('video');
            video.src = './transition.mp4';
            video.loop = true;
            video.muted = true;
            video.playbackRate = 0.5;

            videoTexture = new VideoTexture(video);
            videoTexture.flipY = false;
            videoTexture.encoding = sRGBEncoding;
            videoTexture.minFilter = LinearFilter;
            videoTexture.magFilter = LinearFilter;
            // videoTexture.format = RGBFormat;

            customUniforms = {
                u_time: { value: 0 },
                custom_resolution: { value: new Vector2() },
                custom_texture: { value: null },
                uVideoTexture: { value: videoTexture },
            }
            customShader.onBeforeCompile = shader => {
                shader.vertexShader = vertex
                shader.fragmentShader = fragment

                // Pass any necessary uniforms to the shaders
                shader.uniforms.u_time = customUniforms.u_time;
                shader.uniforms.custom_resolution = customUniforms.custom_resolution;
                shader.uniforms.custom_texture = customUniforms.custom_texture;
                shader.uniforms.uVideoTexture = customUniforms.uVideoTexture;
            };
        }

        const loadModel = () => {
            const loader = new GLTFLoader(loadingManager);
            const dracoLoader = new DRACOLoader();
            dracoLoader.setDecoderPath('./draco/gltf/')
            loader.setDRACOLoader(dracoLoader);
            loader.load("./scene.glb", (gltf) => {
                const { width, height } = manageRenderSize()
                let aspectRatio = width / height;
                gltf.cameras[0].aspectRatio = aspectRatio
                gltf.cameras[0].aspect = aspectRatio
                camera = gltf.cameras[0]
                handleCameraPosition()
                camera.updateProjectionMatrix();
                scene.add(gltf.scene)
                gltf.scene.traverse((child) => {
                    if (child.isMesh) {
                        mesh = child
                        const defaultMat = child.material
                        child.material = customShader
                        child.material.map = defaultMat.map
                        child.material.normalMap = defaultMat.normalMap
                        child.material.roughnessMap = defaultMat.roughnessMap
                        child.material.metalnessMap = defaultMat.metalnessMap
                    }
                })
                mixer = new AnimationMixer(gltf.scene);
                animation = mixer.clipAction(gltf.animations[0].optimize());
                animation.setLoop(LoopOnce);
                animation.clampWhenFinished = true;
                animation.enable = true;
            })
        }


        const handleCameraPosition = () => {
            const width = window.innerWidth
            if (width >= 1024) {
                camera.lookAt(-6, -6, 0);
            }
            if (width <= 1024) {
                camera.position.x = -4.6;
                camera.lookAt(-4.6, -6, 0);
            }
            if (width <= 768) {
                camera.position.x = -0.6;
                camera.lookAt(-0.6, -6, 0);
            }
            if (width <= 425) {
                camera.position.x = 0;
                camera.lookAt(0, -6, 0);
            }
        }

        const onWindowResize = () => {
            let aspectRatio = window.innerWidth / window.innerHeight;
            handleCameraPosition()
            handleResponsiveExposure()
            camera.aspectRatio = aspectRatio
            camera.aspect = aspectRatio
            camera.updateProjectionMatrix();
            renderer.setSize(window?.innerWidth, window?.innerHeight);
            composer.setSize(window?.innerWidth, window?.innerHeight);
        };

        const cleanScene = () => {
            try {
                renderer.forceContextLoss()
                const cleanMaterial = material => {
                    material.dispose()

                    // dispose textures
                    for (const key of Object.keys(material)) {
                        const value = material[key]
                        if (value && typeof value === 'object' && 'minFilter' in value) {
                            value.dispose()
                        }
                    }
                }
                let disposeList = []
                scene.traverse((child) => {
                    if (child.isMesh) {
                        disposeList.push({
                            geometry: child.geometry,
                            material: child.material,
                        })
                    }
                });
                disposeList.forEach(o => {
                    o.geometry.dispose()
                    cleanMaterial(o.material)
                });
                disposeList = []
                let canvas = document.querySelectorAll('.webgl-rendering');
                canvas.forEach((item) => {
                    item.remove()
                })
            }
            catch (e) {
                console.log("error", e)
            }
        }
        const handleMouseMove = (event) => {
            if (window.innerWidth > 600) {
                // Calculate the position of the mouse relative to the center of the screen
                var mouseX = (event.clientX - window.innerWidth / 2);
                var mouseY = (event.clientY - window.innerHeight / 2);

                // Calculate the camera position based on the mouse position
                camera.position.x = mouseX * 0.01;
                camera.position.y = -mouseY * 0.01;
                handleCameraPosition()
            }
        }
        const handleAnimation = () => {
            aboutUs(scene)
        }
        const main = () => {
            createScene()
            handleLoading()
            createRender()
            createCamera()
            circleShader()
            loadModel()
            document.addEventListener('mousemove', handleMouseMove, false);
            window.addEventListener('resize', onWindowResize);
        }

        main()
        return () => {
            window.removeEventListener('resize', onWindowResize);
            document.removeEventListener('mousemove', handleMouseMove, false);
            cleanScene()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div ref={container} id="webgl" draggable="true">
        </div>
    )
}
