import React from 'react';
import { useInView } from 'react-intersection-observer';
import { motion } from 'framer-motion';


function AppInViewAnimation(props) {

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.1,
    });

    const animation = {
        hidden: { y: 150, opacity: 0 },
        visible: { y: 0, opacity: 1 },
    };
    return (

        <motion.div
            ref={ref}
            transition={{ duration: 1, delay: props.delay || 0 }}
            variants={animation}
            initial="hidden"
            animate={inView ? "visible" : "hidden"}
            {...props}
            className='p-0 m-0'
        >
            {props.children}
        </motion.div>
    )
}

export default AppInViewAnimation;