import React, { useEffect } from 'react'
import styles from './preloader.module.scss'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useSelector } from 'react-redux';

export default function Preloader() {
    const percentage = useSelector(state => state.mainPageSliceReducer.percentage)
    useEffect(() => {
        document.querySelector("body").style.overflow = "hidden"
        document.querySelector("body").style.height = "100vh"
        document.querySelector("body").style.maxHeight = "100vh"
        if (percentage === 100) {
            document.querySelector("body").style.overflow = "unset"
            document.querySelector("body").style.height = "auto"
            document.querySelector("body").style.maxHeight = "auto"
        }
    }, [percentage])

    const imageStyles = {
        height: "80%",
        aspectRatio: 1 / 1,
        width: "auto",
        borderRadius: "50%"
    };

    return (
        <div id={styles.preloader} className="preloader">
            <div style={{ width: 250, height: 250 }} className="preload-image">
                <CircularProgressbarWithChildren
                    value={percentage}
                    strokeWidth={2}
                    styles={buildStyles({
                        rotation: 0.30,
                        strokeLinecap: 'butt',
                        textSize: '16px',
                        pathTransitionDuration: 0.5,
                        pathColor: "#000000",
                        textColor: '#f88',
                        trailColor: '#ffffff',
                        backgroundColor: '#ffffff',
                    })}
                >
                    <img src="./images/logo.png" style={imageStyles} alt='Nsanzimfura Logo' />
                </CircularProgressbarWithChildren>
            </div>
        </div >
    )
}
