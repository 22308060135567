

const projectsData = [
    {
        title: "Kigali Comfort",
        preview: './images/kigalibeds.png',
        url: "https://kigalicomfort.com",
        skills: ["Shopify", "MongoDB", "Redis", "ExpressJS", "NodeJs", "TypeScript"],
        details: `
        <p>Kigali Comfort is an E-commerce website built in Shopify with its backend in NodeJS/ExpressJS, TypeScript, Redis and MongoDB database.</p>
        <ul>
        <li>I am behind its UI, and backend where user now is able to come to <a href="https://kigalicomfort.com" target="_blank" rel="noreferrer">Kigali Beds</a>
         and add their favorite Furniture to their cart, Order them to be manufactured, then they follow the manufacturing progress until the product is delivered to their homes;<br />
         Users also can order a manufactured product, which is immediately delivered to their homes
         </li>
        </ul>
        `,
    },

    {
        title: "Yattir",
        preview: './images/yattir.png',
        url: "https://yattir.net/",
        skills: ["TypeScript", "ReactJS", "NextJS", "Sass", "Tailwind-CSS", "Material UI", "Formik", "GraphQL", "Hasura", "WebSockets", "PostgreSQL", "HubSpots", "NestJS", "AdobeXD", "etc"],
        details: `
        <p>Yattir is now a product designed to link employees with employers just like LinkedIn. </p>
        <ul>
            <li>
                 Here, I am behind its UI and responsiveness, realtime messaging and notifications using webSockets, HubSpots messaging. 
            </li>
            <li>
                I had also A big participation on the backend Where I build graphQL queries using Hasura (GraphQl tool)
            </li>
        </ul>
        `,
    },
    {
        title: "Leerecs",
        preview: './images/leerecs.png',
        url: "https://leerecs.com/",
        skills: ["ReactJS", "Bootstrap", "Sass", "PostgreSQL", "Strapi CMS", "NestJS", "etc"],
        details: `
       <p> Leerecs is a music streaming website that was built in 2007. </p>
       <ul>
            <li>My Team and I were required to revamp it into modern website using ReactJS and Content management system(CMS).</li>
            <li>Here, I was frontend developer. I Implemented Adobe XD designs into ReactJS, Sass, Bootstrap, integrated and used Strapi as CMS, to make its content dynamic.</li>
        </ul>
        `,
    },
    {
        title: "Better Me",
        preview: './images/betterme.png',
        url: "https://betterme-fasting.com/",
        skills: ["ReactJS", "Styled-Components", "CSS", "Strapi CMS", "MongoDB", "etc"],
        details: `
        <p>Better Me Fasting is a site that is helping a thousands of sports users.</p>
        <ul>
        <li>I am behind its version 2. I implemented its figma Design, with Styled components for responsiveness.</li>
        <li>Code: <a href="https://github.com/nsanzimfura-eric/better-me-fasting" target="_blank" rel="noreferrer">GitHub</a> </li>
        </ul> 
        `,
    },
    {
        title: "Guez Agency",
        preview: './images/guez.png',
        url: "https://guezagency.com/",
        skills: ["ReactJS", "Sass", "Bootstrap", "etc"],
        details: `
        <p>Guez Agency website. </p>
        <ul>
            <li>I designed and Implemented the Figma UI</li>
        </ul>
        `,
    },
    {
        title: "Side Projects",
        preview: './images/sidePorjects.png',
        url: "https://github.com/nsanzimfura-eric?tab=repositories",
        skills: ["ReactJS", "NextJS", "NestJS", "TypeScript", "Sass", "Tailwind-CSS", "Material UI", "PostgreSQL", "MySQL", "Strapi CMS", "AdobeXD", "Ejs", "HTML/HTML5", "CSS/CSS3", "Payload CMS", "MongoDB", "Squelize", "TypeORM", "Framer motion", "GSAP", "Render", "Firebase", "AWS", "etc"],
        details: `
        <p>
        In my free time I work on my side projects. This always keeps me up to date and learning.
        You can follow the link on the preview to see all projects codes and live links shared on the projects repos on my Github.
        </p>
        <ul>
            <li>As, a shortcut, you can also follow these links to view the projects live: <a target="_blank" rel="noreferrer" href="https://coffee-home.onrender.com/">Coffee Shop</a></li>
            <li>You can also find there codes as well as other more projects on My github Repos: <a href="https://github.com/nsanzimfura-eric?tab=repositories" target="_blank" rel="noreferrer">Nsanzimfura Eric</a> </li>
            <li>Code: <a href="https://github.com/nsanzimfura-eric/coffee-shop" target="_blank" rel="noreferrer">GitHub</a> </li>
            </ul>
        `,
    },
    {
        title: "Data Collection Web & App",
        preview: './images/rsa.png',
        url: "https://rsa-data-collection.web.app/",
        skills: ["ReactJS", "Tailwind-CSS", "PostgreSQL", "TypeORM", "NodeJS", "Typescript", "ExpressJS", "Postman", "Swagger", "React Native", "Email and SMS Templates"],
        details: `
        <p>This is a Data collection website Powered by Rwanda Space Agency(RSA)</p>
        <ul>
            <li>I super-headed its backend and develop its REST-full api from scratch to deployment using ExpressJS, Typescript, with PostgreSQL</li>
            <li>I super-headed its Mobile application development using React Native from scratch</li>
            <li>I worked with my team to Implement its Figma UI layouts for with ReactJS and Tailwind-CSS</li>
            <li>I did other more features like Security enhancement, Formik Validation, etc</li>
        </ul>
        `
    },
    {
        title: "Dyscal",
        preview: './images/dyscal.png',
        url: "https://staging.dysical.xyz/listOfProducts",
        skills: ["ReactJS", "Sass", "Material UI", "AdobeXD", "etc"],
        details: `
        <p>Dyscal is a website for Developing, Designing, and Selling clothings. Welcome to the Easy world when its is done! <br />
        It's still in MVP phase and It is now small close to be launched.</p>
        <ul>
            <li>
                 I was its frontend developer. I designed its Adobe XD layouts and implemented them using ReactJS, Sass and Material UI (MUI).
            </li>
        </ul>
        `,
    },
    {
        title: "Serhant",
        preview: './images/serhant.png',
        url: "https://serhant.com/",
        skills: ["NextJS", "CSS3", "Tailwind-CSS", "MongoDB", "Strapi", 'etc'],
        details: `
        <p>Serhant is Professional website for Real Estate business.</p>
        <ul>
        <li>I worked on its version 2 by optimizing its performance when searching for real estate products and displaying them, which revamped from 5 seconds to a max of 200ms.</li>
        <li>I also worked on its responsiveness Sass and Tailwind-CSS. </li>
        <li>I Used Payload Content Management System (CMS) with MongoDB, and made its content dynamic.</li>
        </ul>
        `,
    },


]
export default projectsData;
