import { apiRoutes, pageClassNames } from "../../utils/constants";

export const navLinkRouters = [
    {
        name: 'Home',
        href: apiRoutes.home,
        className: pageClassNames.home
    },
    {
        name: 'About',
        href: apiRoutes.about,
        className: pageClassNames.about
    },
    {
        name: 'Projects',
        href: apiRoutes.projects,
        className: pageClassNames.projects,
    },
    {
        name: 'Contact',
        href: apiRoutes.contact,
        className: pageClassNames.contact,
    },
    {
        name: 'Resume',
        href: "https://docs.google.com/document/d/1w9IPDqT1T9cSE1kZAkNUgjd09mh7XuQavZRpbAcOc4c/edit?usp=sharing",
        className: null,
    }
];