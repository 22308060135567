import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function animateAboutSection(pxToVh, fps, fpsToDuration, convertTime) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.core.globals("ScrollTrigger", ScrollTrigger);
    const homePageTl = gsap.timeline(
        {
            delay: 1,
            scrollTrigger: {
                trigger: ".about-page",
                start: "top center",
                toggleActions: "play none none reverse",
            }
        }
    );
    homePageTl.fromTo(
        ".title-about",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:54"), ease: "power2.out" },
        0
    );
    homePageTl.fromTo(
        ".sub-title-about",
        { opacity: 0, y: pxToVh(845 - 413) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:53") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:00:14")
    );
    homePageTl.fromTo(
        ".goalbox-title-about",
        { opacity: 0, y: pxToVh(769 - 573) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:03") - convertTime("0:00:00:42"), ease: "power2.out" },
        convertTime("0:00:00:42")
    );
    homePageTl.fromTo(
        ".description-about",
        { opacity: 0, y: pxToVh(257) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:00") - convertTime("0:00:00:52"), ease: "power2.out" },
        convertTime("0:00:00:52")
    );
    homePageTl.fromTo(
        ".mision-title-about",
        { opacity: 0, y: pxToVh(878 - 573) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:10") - convertTime("0:00:00:55"), ease: "power2.out" },
        convertTime("0:00:00:55")
    ); 
    homePageTl.fromTo(
        ".the-right-description",
        { opacity: 0, y: pxToVh(988.9602 - 704.9602) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:27") - convertTime("0:00:00:59"), ease: "power2.out" },
        convertTime("0:00:00:59")
    ); 
    homePageTl.fromTo(
        ".btn-about",
        { opacity: 0, scaleX:0 },
        { opacity: 1, scaleX: 1, duration: convertTime("0:00:02:27") - convertTime("0:00:01:52"), ease: "power2.out" },
        convertTime("0:00:01:52")
    );
}
