import { Modal, Badge } from 'react-bootstrap';
import styles from './projectPopUp.module.scss';
import { motion } from "framer-motion"

export default function ProjectPopUp(props) {
  const { show: project, onHide } = props

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onClick={onHide}
      className={styles.nsanzimfuraPopup}
    >
      <Modal.Header closeButton>
        <h1 className='nsanzimfura-worksTitle'>{project.title}</h1>
        <Modal.Title id="contained-modal-title-vcenter">
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <motion.div initial={{ scale: 0, }} animate={{ scale: 1 }} transition={{ duration: 1 }} className="h-100 w-100 p-0 m-0">
          <div className='previewWrapper'>
            <div
              className='preview'
              style={{
                backgroundImage: `url("${project.preview}")`,
              }}
            >
              <a className='hovered' href={project.url} target='_blank' rel="noreferrer">

                <img src="/images/open.svg" className='navigateBtn' alt="Nsanzimfura project Navigate" />
              </a>
            </div>
            <motion.div initial={{ y: 1000, opacity: 0 }} className="detailsWrapper my-4 p-0" animate={{ y: 0, opacity: 1 }} transition={{ duration: 1, delay: 0.5 }}>
              <div dangerouslySetInnerHTML={{ __html: project.details }} className='detailsList' />
            </motion.div>
          </div>
        </motion.div>
      </Modal.Body>
      <Modal.Footer className='nsanzimfura-modal-footer'>
        <h6 className='py-2'>Skills: </h6>
        <div className='w-100 d-flex'>
          {project.skills?.map((skill, index) => {
            return (
              <Badge className='badge' key={index}>{skill}</Badge>
            )
          })}
        </div>
      </Modal.Footer>
    </Modal>

  );
}

