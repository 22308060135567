import { useState } from 'react';
import styles from './IconsButton.module.scss';


export default function IconsButton(props) {
  const { icon, iconHover, href } = props
  const [hoverEffect, setHoverEffect] = useState(false);

  const handleHoverOver = () => {
    setHoverEffect(true)
  }

  const handleHoverOut = () => {
    setHoverEffect(false)
  }

  return (
    <a
      className={styles.IconsButton}
      href={href}
      target='_blank'
      rel="noreferrer"
      onMouseOut={handleHoverOut}
      onMouseOver={handleHoverOver}
    >
      <img src={hoverEffect ? iconHover : icon} alt="Nsanzimfura Contact Icon" />
    </a>
  );
}

