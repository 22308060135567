import React, { useState } from "react";
import styles from "./projects.module.scss";
import { Container } from "react-bootstrap";
import CarouselFlow, { SingleProjectCard } from "../../components/CarouselFlow/CarouselFlow";
import projectsData from "./data";
import useWindowSize from "../../hooks/useWindowSize";
import ProjectPopUp from "../../components/projectPopUp/projectPopUp"
import NsanzimfuraBtn from "../../components/AppButton/AppButton";
import AppInViewAnimation from "../../components/AppInViewAnimation";
import LottieBg from "../../components/LottieBg";
import lottie1 from './lottie1.json';
import { windowSize } from "../../utils/constants";

export default function Projects() {
  const [modalShow, setModalShow] = useState(null);
  const [firstproject, setFirstproject] = useState(null);

  const { width } = useWindowSize();

  const getProject = (index) => {
    setModalShow(projectsData[index]);
  }

  const clickDataIndex = () => {
    if (firstproject === 0) {
      setFirstproject(null)
    } else {
      setFirstproject(0)
    }
  }

  return (
    <div id={styles.projects} className="projects m-0">
      <LottieBg lottie={lottie1} styles={{ left: "-150px" }} />
      <div className="body pt-5">
        <Container className="staff-text">
          <div className="title title-staff">Recent Projects</div>
          <AppInViewAnimation delay={.9}>
            <div className="sub-title sub-title-staff">
              Each project showcases a blend of innovative design, robust functionality, and cutting-edge technologies, reflecting my Artistic approach to full-stack development. Feel free to explore the live demos to get a deeper understanding of my work.
            </div>
          </AppInViewAnimation>
          <div className="w-100 py-4 d-flex justify-content-between align-items-center">
            <NsanzimfuraBtn onClick={clickDataIndex}>
              <span className="btn-white" >View</span>
            </NsanzimfuraBtn>
          </div>
        </Container>
        <Container className="nsanzimfura-Projects">
          {width > windowSize.sm && <CarouselFlow firstproject={firstproject} projectData={projectsData}></CarouselFlow>}
          {width <= windowSize.sm &&
            projectsData?.map((project, index) => {
              return (
                <div key={index} className="singleProjectSM">
                  <SingleProjectCard showProject={getProject} index={index} data={project} />
                </div>
              )
            })}
          {width <= windowSize.sm && modalShow &&
            <ProjectPopUp show={modalShow}
              onHide={() => setModalShow(null)}
            />
          }
        </Container>
      </div>
    </div>
  );
}
