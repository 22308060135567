import React from "react";
import styles from "./ContactMe.module.scss";
import IconsButton from "../../components/IconsButton/IconsButton";
import { iconsData } from "../../utils/iconsData";
import AppInViewAnimation from "../../components/AppInViewAnimation";
import LottieBg from "../../components/LottieBg";
import lottie1 from '../projects/lottie1.json';


export default function ContactMe() {
  return (
    <div id={styles.contact} className="contact">
      <LottieBg lottie={lottie1} styles={{ left: '-150px', pointerEvents: "none" }} />
      <div className="container_wrapper container">
        <h2 className="title">Let's Talk </h2>
        <AppInViewAnimation>
          <p>A visitor is a blessing for sure! Thank you so much for paying me a visit.
            <br />
            How are my works? I appreciate if you give me a feedback or at least say <span className="ms-2 hello">helloo</span>
          </p>
        </AppInViewAnimation>

        <div className="container-fluid flex-grow-1 h-100 row w-100 p-0 m-0">
          <div className="col-12 col-md-6 booking">
            <iframe src="https://calendly.com/nsanzimfura/interview" title="Book one a meeting slot with me">
            </iframe>
          </div>
          <div className="col-12 bh-dark col-md-6 mediasWrapper">
            <AppInViewAnimation delay={1}>
              <h3 className="mt-5 mt-md-0">Let's Connect Directly here </h3>
            </AppInViewAnimation>

            <div className="medias">
              {iconsData?.map((media) => {
                return <IconsButton key={media.id} icon={media.icon} iconHover={media.hoverIcon} href={media.url} />
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
