import './AppButton.scss';

export default function NsanzimfuraBtn(props) {
  const { children } = props;

  return (
    <button className="nsanzimfura_Btn" {...props}>
      <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 180 63.6">
        <rect width="100%" height="100%" />
        <line className="cls-1" y1="2.5" x2="214" y2="2.5" />
      </svg>
      <div className='Children'>
        {children}
      </div>
    </button>
  );
}

