import React from "react";
import styles from "./hero.module.scss";
import { Container } from "react-bootstrap";
import { pageClassNames } from "../../utils/constants";
import { scrollToClass } from "../../helpers/scrollToPageClassName";
import NavbarComponent from "../../components/navbar/navbar"
import NsanzimfuraBtn from "../../components/AppButton/AppButton";
import ScrollBottom from "../../components/scrollBottom/scrollBottom"

export default function Hero() {

    return (
        <div className="hero p-0" id={styles.hero}>
            <NavbarComponent />
            <Container className="hero-text">
                <div className="title-home d-flex flex-column">
                    <span className="invisibleOnSm">I'm</span>
                    <h1>Nsanzimfura Eric</h1>
                </div>

                <p className="sub-heading sub-heading-home">Software - Full stack Developer</p>
                <small className="small">JavaScript | TypeScript | Java | PHP </small>
                <div className="contact-nsanzimfura">
                    <NsanzimfuraBtn onClick={() => scrollToClass(pageClassNames.projects)}>
                        <span className="btn-white" >Projects</span>
                    </NsanzimfuraBtn>
                </div>
            </Container>
            <ScrollBottom />
        </div>
    );
}
