import { React } from "react";
import LottieAnimation from "../lottie";
import styles from "./scrollBottom.module.scss";
import { Container } from "react-bootstrap";

export default function ScrollBottom() {

  return (
    <Container
      className={styles.bottomSrcoll}
    >
      <div height="60px" width="100px"></div>
      <LottieAnimation json="./lottie/8167-simple-scroll-down-icon.json" height="60px" width="60px" />
      <div></div>
    </Container>
  );
}
