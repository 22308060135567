export const vertexShaderHead = /* glsl */`
attribute vec2 uv2;
varying vec3 v_normal;
varying vec3 v_position;
varying vec2 v_uv1;
varying vec2 v_uv;
`

export const vertexShaderBody = /* glsl */`
  // Transform the normal to world space and normalize it
  v_normal = normalize(normalMatrix * normal);
  v_position = position;
  v_uv = uv2;
  v_uv1 = uv;
`

export const fragmentShaderHead =/* glsl */`
precision mediump float;
uniform float u_time;
varying vec2 v_uv;
varying vec2 v_uv1;
uniform sampler2D uVideoTexture;
`

export const fragmentShaderBody = /* glsl */`
  vec4 color = texture2D(uVideoTexture, v_uv)*10.0;
  gl_FragColor = vec4(mix(gl_FragColor.rgb,color.rgb,color.r),1.0);
`