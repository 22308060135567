/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from 'react';
import styles from './CarouselFlow.module.scss';
import NsanzimfuraBtn from '../AppButton/AppButton';
import ProjectPopUp from '../projectPopUp/projectPopUp';

const RADIUS = 1200;
const FLIP_RANGE = 3;

export default function CarouselFlow(props) {
  const { projectData, firstproject } = props;
  const [modalShow, setModalShow] = useState(null);

  const dataImagesDoubled = [...projectData, ...projectData];
  const el = useRef(null);
  let angleUnit, currentIndex, currentAngle;

  function setTransform(
    el,
    xpos,
    zpos,
    angle,
    flipAngle
  ) {
    el.style.transform = `translateX(${xpos}px) translateZ(${zpos}px) rotateY(${angle}deg) rotateX(${flipAngle}deg)`;
  }

  useEffect(() => {
    angleUnit = 360 / dataImagesDoubled.length;
    currentIndex = currentAngle = 0;
    // target(0, true);
  }, [dataImagesDoubled]);

  function target(index, initial = false) {
    if (!initial && index === currentIndex) PickProject(dataImagesDoubled[index]);

    let deltaAngle = -(index - currentIndex) * angleUnit;
    if (deltaAngle < -180) deltaAngle += 360;
    else if (deltaAngle > 180) deltaAngle -= 360;

    currentAngle += deltaAngle;
    currentIndex = index;
    const cf = el.current;
    cf.style.transform = `translateZ(-1250px) rotateY(${currentAngle}deg)`;

    let fliptAngle = 90;
    const items = cf.children;

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const itemAngle = angleUnit * i;
      const itemAngleRad = (itemAngle * Math.PI) / 180;
      const xpos = Math.sin(itemAngleRad) * RADIUS;
      const zpos = Math.cos(itemAngleRad) * RADIUS;

      let deltaIndex = Math.abs(i - index);
      if (deltaIndex > cf.children.length / 2) {
        deltaIndex = cf.children.length - deltaIndex;
      }
      if (deltaIndex <= FLIP_RANGE) {
        fliptAngle = deltaIndex * (90 / FLIP_RANGE);
      } else fliptAngle = 90;
      setTransform(item, xpos, zpos, itemAngle, fliptAngle);
    }
  }

  const PickProject = (project) => {
    setModalShow(project)
  };

  const handleHideModal = () => {
    setModalShow(null);
  };

  useEffect(() => {
    if (firstproject === 0) {
      target(0, true);
    }
  }, [firstproject])

  return (
    <div className={styles.carouselWrapper}>
      <div className="carouselflow" ref={el}>
        {dataImagesDoubled.map((project, index) => (
          <div key={index} className="carouselflow-item">
            <SingleProjectCard showProject={target} index={index} data={project} />
          </div>
        ))}
      </div>
      {modalShow &&
        <ProjectPopUp
          show={modalShow}
          onHide={handleHideModal}
        />
      }
    </div>
  );
}



export const SingleProjectCard = (props) => {
  const { data, showProject, index } = props;

  return data && (
    <div className={styles.nsanzimfura_singleProject} onClick={() => showProject(index)}>
      <div
        className="nsanzimfura-Bg"
        style={{
          backgroundImage: `url("${data.preview}")`,
        }}
      ></div>
      <div className="nsanzimfura_bottom">
        <div className="name py-2"><h3>{data.title}</h3></div>
        <div className='btns'>
          <NsanzimfuraBtn>
            <img src="/images/Vision.svg" alt="Vision" />
          </NsanzimfuraBtn>
        </div>
      </div>
    </div>
  )
}