import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function animateActivitiesSection(pxToVh, fps, fpsToDuration, convertTime) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.core.globals("ScrollTrigger", ScrollTrigger);
    const homePageTl = gsap.timeline(
        {
            delay: 1,
            scrollTrigger: {
                trigger: ".activities-page",
                start: "top center",
                toggleActions: "play none none reverse",
            }
        }
    );
    homePageTl.fromTo(
        ".title-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:54"), ease: "power2.out" },
        0
    );
    homePageTl.fromTo(
        ".header-1-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:48") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:00:14")
    );
    homePageTl.fromTo(
        ".sub-header-1-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:05") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:00:14")
    );
    homePageTl.fromTo(
        ".header-2-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:48") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:01:05")
    );
    homePageTl.fromTo(
        ".sub-header-2-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:05") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:01:05")
    );
    homePageTl.fromTo(
        ".header-3-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:48") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:01:59")
    );
    homePageTl.fromTo(
        ".sub-header-3-activities",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:01:05") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:01:59")
    );
}
