import styles from './footer.module.scss';
import React from 'react';
import ScrollableSection from "../scrollableSection/scrollableSection"
import LottieBg from '../LottieBg';
import footerLottie from "./footerData.json";
import { scrollToClass } from '../../helpers/scrollToPageClassName';
import { pageClassNames } from '../../utils/constants';
import { iconsData } from '../../utils/iconsData';
import IconsButton from '../IconsButton/IconsButton';



export default function Footer() {
  const year = new Date().getFullYear();

  return (
    <ScrollableSection>
      <div className={styles.footer}>
        <LottieBg lottie={footerLottie} />
        <div className="medias_xlg">
          {iconsData?.map((media) => {
            return <IconsButton key={media.id} icon={media.icon} iconHover={media.hoverIcon} href={media.url} />
          })}
          <div className='tree'>
            <a href="https://linktr.ee/nsanzimfura" className='treeLink' target='_blank' rel='noreferrer'>LinkTree</a>
          </div>
        </div>
        <div className='container bg-none'>
          <img src="/images/logo.png" alt="Nsanzimfura EricLogo" className='logo' onClick={(e) => {
            e.preventDefault();
            scrollToClass(pageClassNames.home)
          }} />
          <div className="medias_lg">
            {iconsData?.map((media) => {
              return <IconsButton key={media.id} icon={media.icon} iconHover={media.hoverIcon} href={media.url} />
            })}
            <div className='tree'>
              <a href="https://linktr.ee/nsanzimfura" className='treeLink' target='_blank' rel='noreferrer'>LinkTree</a>
            </div>
          </div>
        </div>
        <div className='copyRight'>
          &copy; {year}<h1 className='mx-3'>🌸 Nsanzimfura eric</h1> <p>All rights reserved!</p>
        </div>
      </div>

    </ScrollableSection>
  );
}

