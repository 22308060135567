import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function animateHeroPage(pxToVh, fps, fpsToDuration, convertTime) {
  gsap.registerPlugin(ScrollTrigger);
  gsap.core.globals("ScrollTrigger", ScrollTrigger);
  const homePageTl = gsap.timeline(
    {
      delay: 2,
      scrollTrigger: {
        trigger: ".home-page",
        start: "top 80%",
        end: "center 50%",
        toggleActions: "play none none reverse"
      }
    }
  );
  homePageTl.fromTo(
    ".title-home",
    { opacity: 0, y: pxToVh(271) },
    { opacity: 1, y: 0, duration: 1 + 13 / fps, ease: "power2.out" },
    0
  );
  homePageTl.fromTo(
    ".sub-heading-home",
    { opacity: 0, y: pxToVh(940.4602 - 604.4602) },
    {
      opacity: 1,
      y: 0,
      duration: fpsToDuration(41),
      ease: "power2.out",
      delay: fpsToDuration(24),
    },
    0
  );
  homePageTl.fromTo(
    ".btn-white-home-1",
    { opacity: 0, scaleX: 0 },
    {
      opacity: 1,
      scaleX: 1,
      duration: fpsToDuration(24),
      ease: "back.out(1.7)",
      delay: fpsToDuration(50),
    },
    0
  );
  homePageTl.fromTo(
    ".btn-white-home-2",
    { opacity: 0, scaleX: 0 },
    {
      opacity: 1,
      scaleX: 1,
      duration: convertTime("0:00:01:36") - convertTime("0:00:01:07"),
      ease: "back.out(1.7)",
      delay: convertTime("0:00:01:07"),
    },
    0
  );
  homePageTl.fromTo(
    ".points-home",
    { opacity: 0, scaleX: 0 },
    {
      opacity: 1,
      scaleX: 1,
      stagger: convertTime("0:00:00:33") - convertTime("0:00:00:28"),
      duration: convertTime("0:00:00:53") - convertTime("0:00:00:28"),
      ease: "back.out(1.7)",
      delay: convertTime("0:00:00:28"),
    },
    0
  );
  homePageTl.fromTo(
    ".single-link-home",
    { opacity: 0, scaleX: 0 },
    {
      opacity: 1,
      scaleX: 1,
      stagger: convertTime("0:00:00:33") - convertTime("0:00:00:30"),
      duration: convertTime("0:00:00:42") - convertTime("0:00:00:30"),
      ease: "back.out(1.7)",
      delay: convertTime("0:00:00:30"),
    },
    0
  );
  homePageTl.fromTo(
    ".menu-icon-home",
    { opacity: 0, scaleX: 0 },
    {
      opacity: 1,
      scaleX: 1,
      duration: convertTime("0:00:01:32") - convertTime("0:00:01:11"),
      ease: "back.out(1.7)",
      delay: convertTime("0:00:01:11"),
    },
    0
  );
  homePageTl.fromTo(
    ".scroll-home",
    { opacity: 0, scaleY: 0 },
    {
      opacity: 1,
      scaleY: 1,
      duration: convertTime("0:00:01:53") - convertTime("0:00:01:28"),
      ease: "back.out(1.7)",
      delay: convertTime("0:00:01:28"),
    },
    0
  );
}
