import React, { useRef, useEffect } from 'react';
import lottie from 'lottie-web';

const LottieAnimation = ({ json, width, height }) => {
    const container = useRef(null);

    useEffect(() => {
        const anim = lottie.loadAnimation({
            container: container.current,
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: json,
        });
        return () => anim.destroy();
    }, [json]);

    return <div ref={container} style={{ width: width, height: height }} />;
};

export default LottieAnimation;
