import React, { useState, useEffect } from 'react';
import FontFaceObserver from 'fontfaceobserver';
// components 
import SideNav from "./components/sideNav/sideNav";
import Footer from "./components/footer/footer";
import { animateHeroPage } from "./components/animateHeroPage";
import { animateAboutSection } from "./components/animateAboutSection";
import { animateTeamSection } from "./components/animateTeamSection";
import { animateActivitiesSection } from "./components/animateActivitiesSection";
import { animateInvestmentsSection } from "./components/animateInvestmentsSection";
import { animatePrelaoderSection } from "./components/animatePreLoaderSection";
import Webgl from './components/Webgl';
// pages
import About from "./pages/about/about";
import Projects from "./pages/projects/projects";
import ContactMe from "./pages/ContactMe/ContactMe";
import Hero from "./pages/hero/Hero";

import { useSelector } from "react-redux";
import { useWindowDimension } from "./hooks/useWindowDimension";
import "./styles/variables.scss";
import "./index.scss";
import Preloader from './components/preloader/Preloader';


function App() {
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    const loadFonts = async () => {
      const fonts = [
        new FontFaceObserver('Arapey'),
        new FontFaceObserver('Arima'),
        new FontFaceObserver('Poppins'),
        new FontFaceObserver('Inknut Antiqua'),
      ];

      try {
        await Promise.all(fonts.map(font => font.load()));
        setFontsLoaded(true);
      } catch (error) {
        console.error('Some fonts are not loaded:', error);
        setFontsLoaded(true);
      }
    };

    loadFonts();
  }, [])

  if (!fontsLoaded) {
    return <Preloader />;
  }

  return (
    <Landing />
  );
}

function Landing() {
  const fps = 60;
  const pxToVh = (px) => {
    return `${(px / 1080) * 100}vh`;
  };
  const fpsToDuration = (duration) => {
    return duration / fps;
  };

  function convertTime(timeString) {
    const timeComponents = timeString.split(":").map(parseFloat);
    let seconds =
      timeComponents[0] * 3600 + timeComponents[1] * 60 + timeComponents[2];
    seconds += timeComponents[3] / fps;
    return seconds;
  }

  const percentage = useSelector(state => state.mainPageSliceReducer.percentage)

  const { width } = useWindowDimension()

  useEffect(() => {
    if (percentage === 100) {
      animatePrelaoderSection(pxToVh, fps, fpsToDuration, convertTime)
      if (width > 600) {
        animateHeroPage(pxToVh, fps, fpsToDuration, convertTime);
        animateAboutSection(pxToVh, fps, fpsToDuration, convertTime);
        animateTeamSection(pxToVh, fps, fpsToDuration, convertTime);
        animateActivitiesSection(pxToVh, fps, fpsToDuration, convertTime);
        animateInvestmentsSection(pxToVh, fps, fpsToDuration, convertTime)
      }
    }
  }, [percentage, width]);

  return (
    <div id="home" className="w-100 p-0 m-0">
      <Preloader />
      <Webgl />
      <SideNav />
      <Hero />
      <About />
      <Projects />
      <ContactMe />
      <Footer />
    </div>
  );
}

export default App