import React, { useState, useEffect } from 'react';
import styles from './sideNav.module.scss';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { apiRoutes, pageClassNames } from '../../utils/constants';
import { scrollToClass } from '../../helpers/scrollToPageClassName';


export default function SideNav() {
  const [active, setActive] = useState(0);
  const pages = [
    { page: 1, href: apiRoutes.home, trigger: pageClassNames.home },
    { page: 2, href: apiRoutes.about, trigger: pageClassNames.about },
    { page: 3, href: apiRoutes.projects, trigger: pageClassNames.projects },
    { page: 4, href: apiRoutes.contact, trigger: pageClassNames.contact },
  ]
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    pages.forEach((item, idx) => {
      gsap.to(item.trigger, {
        scrollTrigger: {
          trigger: item.trigger,
          start: "top center",
          onEnter: () => {
            setActive(idx)
          },
          onLeaveBack: () => {
            setActive(idx)
          },
          onUpdate: () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition < 100) {
              setActive(0)
            }
          }
        }
      })
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div id={styles.sideNav}>
      <div className="points">
        {pages.map((point, i) => <div key={i} href={point.href} className={`point ${active === i && "active"}`} onClick={() => scrollToClass(point.trigger)}>
          <div className="point-circle"></div>
        </div>)}
      </div>
    </div>
  )
}
