import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function animatePrelaoderSection(pxToVh, fps, fpsToDuration, convertTime) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.core.globals("ScrollTrigger", ScrollTrigger);
    const homePageTl = gsap.timeline(
        {
            delay: 1,
        }
    );
    homePageTl.fromTo(
        ".preloader",
        { opacity: 1, x: 0 },
        { opacity: 0, x: "-120vw", duration: convertTime("0:00:01:00"), ease: "power4.in" },
        0
    );
    homePageTl.fromTo(
        ".preload-image",
        { opacity: 1, x: 0 },
        { opacity: 0, x: -250, duration: convertTime("0:00:00:25"), ease: "power4.in" },
        convertTime("0:00:00:0")
    );

}
