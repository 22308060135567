export const apiRoutes = {
    home: "#hero",
    about: "#about",
    skills: "#skills",
    projects: "#projects",
    contact: "#contact",
}
export const pageClassNames = {
    home: ".hero",
    about: ".about",
    projects: ".projects",
    skills: ".skills",
    contact: ".contact",
}

export const windowSize = {
    md: 768,
    desktop: 1440,
    largeDesktop: 1920,
    sm: 480,
    xxs: 320,
    xls: 600,
    xlsh: 500,
    xms: 375,
    lg: 992,
    xl: 1200,
    xlg: 1440,
    xxlg: 5000,
}
