import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

export function animateInvestmentsSection(pxToVh, fps, fpsToDuration, convertTime) {
    gsap.registerPlugin(ScrollTrigger);
    gsap.core.globals("ScrollTrigger", ScrollTrigger);
    const homePageTl = gsap.timeline(
        {
            delay: 1,
            scrollTrigger: {
                trigger: ".investments-page",
                start: "top center",
                toggleActions: "play none none reverse",
            }
        }
    );
    homePageTl.fromTo(
        ".title-investment",
        { opacity: 0, y: pxToVh(598 - 291) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:54"), ease: "power2.out" },
        0
    );
    homePageTl.fromTo(
        ".sub-title-investment",
        { opacity: 0, y: pxToVh(845 - 413) },
        { opacity: 1, y: 0, duration: convertTime("0:00:00:53") - convertTime("0:00:00:14"), ease: "power2.out" },
        convertTime("0:00:00:14")
    );
    homePageTl.fromTo(
        ".blue-box",
        { opacity: 0 },
        { opacity: 1, duration: convertTime("0:00:01:03") - convertTime("0:00:00:42"), stagger: "0.3", ease: "power2.out" },
        convertTime("0:00:00:42")
    );
}
