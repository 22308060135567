import styles from './scrollableSection.module.scss';
import { useScroll, useTransform } from 'framer-motion';
import React, { useRef } from 'react';
import { motion } from "framer-motion"


export default function ScrollableSection({ children }) {
  const sectionRef = useRef(null);

  const { scrollYProgress } = useScroll({
    target: sectionRef,
    offset: ["0 0.8", "0.8 1"]
  });
  const opacityProgress = useTransform(scrollYProgress, [0.6, 1,], [0.6, 1]);
  const scaleProgress = useTransform(scrollYProgress, [0, 1,], [0.5, 1]);

  return (
    <motion.div
      ref={sectionRef}
      className={styles.scrollableSection}
      style={{ opacity: opacityProgress, scale: scaleProgress }}
    >
      {children}
    </motion.div>
  );
}

