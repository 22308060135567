import React, { useRef, useState } from 'react';
import styles from './navbar.module.scss';
import { navLinkRouters } from "./navbarLinks"
import { Container, Navbar, Nav } from 'react-bootstrap';
import { apiRoutes, windowSize } from "../../utils/constants";
import useWindowSize from '../../hooks/useWindowSize';
import { scrollToClass } from '../../helpers/scrollToPageClassName';

export default function NavbarComponent() {
    const { width } = useWindowSize()
    const [navbarOpened, setNavbarOpened] = useState(false);

    // navbar
    const togglerRef = React.useRef(null);
    const toggleNavbar = () => {
        if (width <= windowSize.lg) {
            setNavbarOpened(!navbarOpened);
            togglerRef?.current?.click();
        }
    }

    const nsanzimfuraResumeRef = useRef(null)

    return (
        <Navbar expand="lg" className={styles.navbarComponent}>
            <Container className={navbarOpened && width <= windowSize.lg ? "navbarOpened" : ""}>
                <Navbar.Brand href={apiRoutes.home} className="logo me-auto bg-none" ><img src="./images/logo.png" alt="Nsanzimfura Eric Logo" /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" ref={togglerRef} hidden />
                <img src="./images/menu.svg" alt="Nsanzimfura Logo Loading" className='menu-icon-home d-block d-lg-none' onClick={toggleNavbar} />
                <Navbar.Collapse>
                    <Nav className="navBarLinks mx-auto" >
                        {navLinkRouters.map((link, i) => {
                            return <div key={i} className='single-link single-link-home' onClick={(e) => {
                                e.preventDefault();
                                toggleNavbar()
                                scrollToClass(link.className)
                                if (link.name === "Resume") {
                                    nsanzimfuraResumeRef.current?.click()
                                }
                            }}>{link.name}</div>
                        })}
                        <a href="https://docs.google.com/document/d/1w9IPDqT1T9cSE1kZAkNUgjd09mh7XuQavZRpbAcOc4c/edit?usp=sharing" target='_blank' rel="noreferrer" hidden ref={nsanzimfuraResumeRef}>Resume</a>
                    </Nav>
                </Navbar.Collapse>
                <img src="./images/menu.svg" alt="Nsanzimfura Logo Loading" className='menu-icon-home d-none d-lg-block' />
            </Container>
        </Navbar>
    )
}
